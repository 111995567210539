.gallery14-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery14-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery14-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery14-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery14-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: rgb(241,241,242);
}
.gallery14-image11 {
  width: 90%;
  height: 250px;
  resize: both;
  max-width: 600px;
  align-self: center;
  margin-top: 10px;
  border-radius: 15px;
}
.gallery14-text1 {
  fill: var(--dl-color-gray-black);
  color: #008242;
  font-size: 20px;
  align-self: center;
  font-family: "extraa";
}
.gallery14-text2 {
  text-align: center;
  font-family: "bold";
}
.gallery14-text3 {
  fill: #f05b25;
  color: #f05b25;
  font-size: 17px;
  padding: 10px;
  font-family: "extraa";
}
.gallery14-container2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: rgb(241,241,242);
}
.gallery14-image12 {
  width: 100%;
  height: 250px;
  resize: both;
  max-width: 600px;
  align-self: center;
  margin-top: 10px;
  border-radius: 15px;
}
.gallery14-text4 {
  fill: var(--dl-color-gray-black);
  color: #008242;
  font-size: 20px;
  align-self: center;
  font-family: "extraa";
}
.gallery14-text5 {
  text-align: center;
  font-family: "bold";
}
.gallery14-text6 {
  fill: #f05b25;
  color: #f05b25;
  font-size: 20px;
  font-family: "extraa";
}
.gallery14-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: rgb(241,241,242);
}
.gallery14-image13 {
  width: 100%;
  height: 250px;
  resize: both;
  max-width: 600px;
  align-self: center;
  margin-top: 10px;
  border-radius: 15px;
}
.gallery14-text7 {
  fill: var(--dl-color-gray-black);
  color: #008242;
  font-size: 20px;
  align-self: center;
  font-family: "extraa";
}
.gallery14-text8 {
  text-align: center;
  font-family: "bold";
}
.gallery14-text9 {
  fill: #f05b25;
  color: #f05b25;
  font-size: 20px;
  font-family: "extraa";
}


@media(max-width: 991px) {
  .gallery14-content {
    align-items: center;
    flex-direction: column;
  }
  .gallery14-image11 {
    flex: 0 0 auto;
    width: 90%;
    height: 360px;
  }
  .gallery14-image12 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery14-image13 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
}
@media(max-width: 479px) {
  .gallery14-gallery3 {
    margin-top: var(--dl-space-space-twounits);
  }
  .gallery14-content{
    margin-top: 97px;
    margin-left: -54px;
    align-self: center;
  }
  .gallery14-container1 {
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .gallery14-image11 {
    width: 80%;
    height: 150px;
  }
  .gallery14-container2 {
    width: 80%;
  }
  .gallery14-image12 {
    width: 80%;
    height: 150px;
  }
  .gallery14-container3 {
    width: 80%;
  }
  .gallery14-image13 {
    width: 80%;
    height: 150px;
  }
  .gallery14root-class-name {
    margin-top: var(--dl-space-space-halfunit);
  }
  .gallery14root-class-name1 {
    margin-top: var(--dl-space-space-sixunits);
  }
}
