.menu-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.menu-products {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 0px; /* Space between grid items */
  padding: 10px; /* Padding around the grid */
  align-self: center;
}

@media (max-width: 768px) {
  .menu-products {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }
}

@media (max-width: 480px) {
  .menu-products {
    grid-template-columns: 1fr; /* One column for mobile phones */
  }
}

.menu-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.menu-image1 {
  width: 200px;
  height: auto;
}
.menu-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.menu-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.menu-links1 {
  flex: 1;
  width: 702px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: flex-end;
}
.menu-navlink1 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  transition: 0.3s;
  font-family: "extraa";
  font-weight: 700;
  text-decoration: none;
}
.menu-navlink1:hover {
  color: orange;
}
.menu-navlink2 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.menu-navlink3 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.menu-navlink4 {
  color: rgb(0, 130, 66);
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.menu-buttons1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.menu-login1 {
  display: flex;
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
}
.menu-icon10 {
  fill: #008242;
  width: 24px;
  height: 24px;
  z-index: 100;
  border-color: var(--dl-color-success-500);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.menu-icon12 {
  fill: #008242;
  width: 24px;
  height: 24px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}
.menu-burger-menu {
  display: none;
}
.menu-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.menu-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.menu-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.menu-logo {
  height: 2rem;
}
.menu-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.menu-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.menu-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.menu-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.menu-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.menu-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.menu-text14 {
  margin-bottom: var(--dl-space-space-unit);
}
.menu-buttons2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.menu-login2 {
  margin-right: var(--dl-space-space-twounits);
}
.menu-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.menu-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.menu-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.menu-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
}
.menu-text15 {
  color: #008242;
  align-self: flex-start;
  font-family: "extraa";
}
.menu-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  justify-content: center;
}
.menu-button1 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  transition: 0.3s;
  font-family: "extraa";
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-button1:focus {
  color: white;
  border-color: white;
  background-color: #f05b25;
}
.menu-button2 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  transition: 0.3s;
  font-family: "extraa";
  margin-left: var(--dl-space-space-unit);
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-button2:focus {
  color: white;
  border-color: white;
  background-color: #f05b25;
}
.menu-button3 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  font-family: "extraa";
  margin-left: var(--dl-space-space-unit);
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-button4 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  font-family: "extraa";
  margin-left: var(--dl-space-space-unit);
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-button5 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  font-family: "extraa";
  margin-left: var(--dl-space-space-unit);
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-button6 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  font-family: "extraa";
  margin-left: var(--dl-space-space-unit);
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-button7 {
  fill: #008242;
  color: rgb(0, 130, 66);
  width: 145px;
  height: 58px;
  font-size: 20px;
  font-family: "extraa";
  margin-left: var(--dl-space-space-unit);
  border-color: #008242;
  border-style: solid;
  border-width: 2px;
  background-color: var(--dl-color-gray-900);
}
.menu-footer1 {
  width: 100%;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #008242;
}
.menu-container14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.menu-links-container {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
}
.menu-container15 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.menu-product-container1 {
  width: 156px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-text19 {
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.menu-product-container2 {
  width: 156px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-text28 {
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.menu-company-container {
  width: 211px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-text38 {
  color: #fffcfc;
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.menu-button8 {
  color: #ffffff;
  width: 204px;
  height: 36px;
  align-self: flex-start;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  border-width: 0px;
  background-color: #f05b25;
}
.menu-container16 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-contact {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.menu-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.menu-text47 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.menu-icon-group2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.menu-icon24 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.menu-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.menu-icon28 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.menu-container17 {
  width: 100%;
  height: 222px;
  position: relative;
  align-items: center;
}
.menu-text48 {
  color: #008242;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-family: "extraa";
  font-weight: 800;
}
.menu-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.menu-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 10%;
  position: relative;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  justify-content: center;
}
.menu-textinput1 {
  top: -12px;
  left: 549px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  position: absolute;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.menu-textinput2 {
  top: -12px;
  left: 317px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  position: absolute;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.menu-button9 {
  left: 876px;
  color: rgb(255, 254, 254);
  width: var(--dl-size-size-large);
  bottom: -22px;
  margin: auto;
  position: absolute;
  font-size: 18px;
  align-self: center;
  font-family: "extraa";
  border-color: #008242;
  background-color: #008242;
}
.menu-container20 {
  width: 100%;
  height: 50%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  justify-content: center;
}
.menu-image2 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.menu-image3 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.menu-image4 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.menu-footer2 {
  width: 100%;
  height: 70px;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #008242;
}
.menu-text49 {
  top: 31px;
  left: 0px;
  color: #ffffff;
  right: 0px;
  margin: auto;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "bold";
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}
@media(max-width: 1600px) {
  .menu-footer1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .menu-product-container1 {
    height: auto;
    align-self: stretch;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 10rem;
  }
  .menu-text19 {
    color: #ffffff;
    font-family: bold;
    font-weight: 700;
    line-height: 1.5;
  }
  .menu-product-container2 {
    height: auto;
    align-self: stretch;
    margin-left: 0px;
    margin-right: 10rem;
  }
  .menu-text28 {
    font-family: bold;
    font-weight: 700;
    margin-right: 0rem;
  }
  .menu-text29 {
    color: var(--dl-color-gray-white);
  }
  .menu-text30 {
    color: var(--dl-color-gray-white);
  }
  .menu-text31 {
    color: var(--dl-color-gray-white);
  }
  .menu-text32 {
    color: var(--dl-color-gray-white);
  }
  .menu-text33 {
    color: var(--dl-color-gray-white);
  }
  .menu-text34 {
    color: var(--dl-color-gray-white);
  }
  .menu-text35 {
    color: var(--dl-color-gray-white);
  }
  .menu-text36 {
    color: var(--dl-color-gray-white);
  }
  .menu-company-container {
    align-self: stretch;
  }
}
@media(max-width: 1200px) {
  .menu-container18 {
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .menu-container15 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .menu-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .menu-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .menu-button9 {
    top: 6px;
    left: 700px;
    right: 92px;
  }
}
@media(max-width: 767px) {
  .menu-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .menu-desktop-menu {
    display: none;
  }
  .menu-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-text10 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .menu-text11 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .menu-text12 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .menu-text13 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .menu-text14 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .menu-container14 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .menu-container15 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .menu-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .menu-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .menu-container19 {
    display: block;
  }
  .menu-textinput1 {
    left: 250px;
  }
  .menu-textinput2 {
    left: 10px;
  }
  .menu-button9 {
    left: 400px;
  }
  .menu-footer2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .menu-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .menu-mobile-menu {
    padding: 16px;
  }
  .menu-container13 {
    width: 95%;
    flex-wrap: wrap;
    align-self: center;
    margin-left: 0px;
  }
  .menu-button3 {
    align-self: center;
    margin-top: 5px;
    margin-left: 5px;
  }
  .menu-button4 {
    margin-top: 5px;
  }
  .menu-button5 {
    margin-top: 5px;
    margin-left: 5px;
  }
  .menu-button6 {
    margin-top: 5px;
  }
  .menu-button7 {
    margin-top: 5px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .menu-footer1 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .menu-container14 {
    align-items: center;
    flex-direction: column;
  }
  .menu-links-container {
    width: 50%;
    display: block;
    align-items: center;
    flex-direction: column;
  }
  .menu-container15 {
    display: block;
    margin-right: 0px;
  }
  .menu-container16 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .menu-contact {
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .menu-container18 {
    display: block;
  }
  .menu-container19 {
    display: block;
  }
  .menu-textinput1 {
    left: 250px;
  }
  .menu-textinput2 {
    left: 10px;
  }
  .menu-button9 {
    left: 80px;
    margin-top: var(--dl-space-space-twounits);
  }
  .menu-container20 {
    margin-top: var(--dl-space-space-twounits);
  }
  .menu-footer2 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
}

.gallery14-text3 {
  fill: #f05b25;
  color: #f05b25;
  font-size: 17px;
  padding: 10px;
  font-family: "extraa";
}
.gallery14-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery14-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery14-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery14-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery14-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: rgb(241,241,242);
}
.gallery14-image11 {
  width: 90%;
  height: 250px;
  resize: both;
  max-width: 600px;
  align-self: center;
  margin-top: 10px;
  border-radius: 15px;
}
.gallery14-text1 {
  fill: var(--dl-color-gray-black);
  color: #008242;
  font-size: 20px;
  align-self: center;
  font-family: "extraa";
}
.gallery14-text2 {
  text-align: center;
  font-family: "bold";
}

.gallery14-container2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: rgb(241,241,242);
}
.gallery14-image12 {
  width: 100%;
  height: 250px;
  resize: both;
  max-width: 600px;
  align-self: center;
  margin-top: 10px;
  border-radius: 15px;
}
.gallery14-text4 {
  fill: var(--dl-color-gray-black);
  color: #008242;
  font-size: 20px;
  align-self: center;
  font-family: "extraa";
}
.gallery14-text5 {
  text-align: center;
  font-family: "bold";
}
.gallery14-text6 {
  fill: #f05b25;
  color: #f05b25;
  font-size: 20px;
  font-family: "extraa";
}
.gallery14-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: rgb(241,241,242);
}
.gallery14-image13 {
  width: 100%;
  height: 250px;
  resize: both;
  max-width: 600px;
  align-self: center;
  margin-top: 10px;
  border-radius: 15px;
}
.gallery14-text7 {
  fill: var(--dl-color-gray-black);
  color: #008242;
  font-size: 20px;
  align-self: center;
  font-family: "extraa";
}
.gallery14-text8 {
  text-align: center;
  font-family: "bold";
}
.gallery14-text9 {
  fill: #f05b25;
  color: #f05b25;
  font-size: 20px;
  font-family: "extraa";
}


@media(max-width: 991px) {
  .gallery14-content {
    align-items: center;
    flex-direction: column;
  }
  .gallery14-image11 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery14-image12 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery14-image13 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
}
@media(max-width: 479px) {
  .gallery14-gallery3 {
    margin-top: var(--dl-space-space-twounits);
  }
  .gallery14-container1 {
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .gallery14-image11 {
    width: 80%;
    height: 150px;
  }
  .gallery14-container2 {
    width: 80%;
  }
  .gallery14-image12 {
    width: 80%;
    height: 150px;
  }
  .gallery14-container3 {
    width: 80%;
  }
  .gallery14-image13 {
    width: 80%;
    height: 150px;
  }
  .gallery14root-class-name {
    margin-top: var(--dl-space-space-halfunit);
  }
  .gallery14root-class-name1 {
    margin-top: var(--dl-space-space-sixunits);
  }
}

