.gallery8-gallery3 {
  width: 100%;
  height: 595px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.gallery8-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery8-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery8-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery8-slider {
  width: 100%;
  height: 70%;
  display: inline-block;
}
.gallery8-slider-slide1 {
  display: flex;
  border-radius: 20px;
  justify-content: center;
}
.gallery8-image1 {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.gallery8-slider-slide2 {
  height: calc(100% - 20px);
  display: flex;
}
.gallery8-image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.gallery8-slider-button-prev {
  color: var(--dl-color-gray-white);
}
.gallery8-slider-button-next {
  color: var(--dl-color-gray-white);
}
.gallery8-slider-pagination {
  color: var(--dl-color-theme-primary2);
  display: block;
}
.gallery8-slider-pagination-bullet1 {
  color: var(--dl-color-theme-primary2);
  background-color: var(--dl-color-theme-primary2);
}
.gallery8-slider-pagination-bullet2 {
  color: var(--dl-color-theme-primary2);
  background-color: var(--dl-color-theme-primary2);
}
.gallery8root-class-name {
  width: 100%;
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.gallery8root-class-name1 {
  width: 90%;
  height: 600px;
  margin-bottom: var(--dl-space-space-twounits);
}
@media(max-width: 1600px) {
  .gallery8root-class-name1 {
    width: auto;
    align-self: stretch;
  }
}
@media(max-width: 1200px) {
  .gallery8root-class-name {
    width: auto;
    align-self: stretch;
  }
}
@media(max-width: 991px) {
  .gallery8-content {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .gallery8-slider {
    height: 550px;
  }
}
@media(max-width: 479px) {
  .gallery8-gallery3 {
    height: 257px;
  }
  .gallery8-slider {
    height: 440px;
  }
  .gallery8root-class-name {
    width: auto;
    height: auto;
    align-self: stretch;
  }
  .gallery8root-class-name1 {
    height: auto;
  }
}
