.events-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.events-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.events-image1 {
  width: 200px;
  height: auto;
}
.events-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.events-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.events-links1 {
  flex: 1;
  width: 702px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: flex-end;
}
.events-navlink1 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  transition: 0.3s;
  font-family: "extraa";
  font-weight: 700;
  text-decoration: none;
}
.events-navlink1:hover {
  color: orange;
}
.events-navlink2 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.events-navlink3 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.events-navlink4 {
  color: rgb(0, 130, 66);
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.events-buttons1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.events-login1 {
  display: flex;
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
}
.events-icon10 {
  fill: #008242;
  width: 24px;
  height: 24px;
  z-index: 100;
  border-color: var(--dl-color-success-500);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.events-icon12 {
  fill: #008242;
  width: 24px;
  height: 24px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}
.events-burger-menu {
  display: none;
}
.events-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.events-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.events-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.events-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.events-logo {
  height: 2rem;
}
.events-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.events-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.events-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.events-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.events-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.events-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.events-text14 {
  margin-bottom: var(--dl-space-space-unit);
}
.events-buttons2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.events-login2 {
  margin-right: var(--dl-space-space-twounits);
}
.events-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.events-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.events-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.events-container12 {
  width: 100%;
  height: 525px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.events-icon24 {
  fill: #f05b25;
  color: #f05b25;
  width: 40px;
  height: 40px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.events-text15 {
  color: #f05b25;
  font-size: 60px;
  font-family: "extraa";
}
.events-footer1 {
  width: 100%;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #008242;
}
.events-container13 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.events-links-container {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
}
.events-container14 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.events-product-container1 {
  width: 156px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.events-text16 {
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.events-product-container2 {
  width: 156px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.events-text25 {
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.events-company-container {
  width: 211px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.events-text35 {
  color: #fffcfc;
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.events-button1 {
  color: #ffffff;
  width: 204px;
  height: 36px;
  align-self: flex-start;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  border-width: 0px;
  background-color: #f05b25;
}
.events-container15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.events-contact {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.events-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.events-text44 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.events-icon-group2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.events-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.events-icon28 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.events-icon30 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.events-container16 {
  width: 100%;
  height: 222px;
  position: relative;
  align-items: center;
}
.events-text45 {
  color: #008242;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-family: "extraa";
  font-weight: 800;
}
.events-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.events-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 10%;
  position: relative;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  justify-content: center;
}
.events-textinput1 {
  top: -12px;
  left: 549px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  position: absolute;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.events-textinput2 {
  top: -12px;
  left: 317px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  position: absolute;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.events-button2 {
  left: 876px;
  color: rgb(255, 254, 254);
  width: var(--dl-size-size-large);
  bottom: -22px;
  margin: auto;
  position: absolute;
  font-size: 18px;
  align-self: center;
  font-family: "extraa";
  border-color: #008242;
  background-color: #008242;
}
.events-container19 {
  width: 100%;
  height: 50%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  justify-content: center;
}
.events-image2 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.events-image3 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.events-image4 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.events-footer2 {
  width: 100%;
  height: 70px;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #008242;
}
.events-text46 {
  top: 31px;
  left: 0px;
  color: #ffffff;
  right: 0px;
  margin: auto;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "bold";
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}
@media(max-width: 1600px) {
  .events-footer1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .events-product-container1 {
    height: auto;
    align-self: stretch;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 10rem;
  }
  .events-text16 {
    color: #ffffff;
    font-family: bold;
    font-weight: 700;
    line-height: 1.5;
  }
  .events-product-container2 {
    height: auto;
    align-self: stretch;
    margin-left: 0px;
    margin-right: 10rem;
  }
  .events-text25 {
    font-family: bold;
    font-weight: 700;
    margin-right: 0rem;
  }
  .events-text26 {
    color: var(--dl-color-gray-white);
  }
  .events-text27 {
    color: var(--dl-color-gray-white);
  }
  .events-text28 {
    color: var(--dl-color-gray-white);
  }
  .events-text29 {
    color: var(--dl-color-gray-white);
  }
  .events-text30 {
    color: var(--dl-color-gray-white);
  }
  .events-text31 {
    color: var(--dl-color-gray-white);
  }
  .events-text32 {
    color: var(--dl-color-gray-white);
  }
  .events-text33 {
    color: var(--dl-color-gray-white);
  }
  .events-company-container {
    align-self: stretch;
  }
}
@media(max-width: 1200px) {
  .events-container17 {
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .events-container14 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .events-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .events-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .events-button2 {
    top: 6px;
    left: 700px;
    right: 92px;
  }
}
@media(max-width: 767px) {
  .events-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .events-desktop-menu {
    display: none;
  }
  .events-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .events-text10 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .events-text11 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .events-text12 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .events-text13 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .events-text14 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .events-container13 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .events-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .events-container14 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .events-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .events-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .events-container18 {
    display: block;
  }
  .events-textinput1 {
    left: 250px;
  }
  .events-textinput2 {
    left: 10px;
  }
  .events-button2 {
    left: 400px;
  }
  .events-footer2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .events-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .events-mobile-menu {
    padding: 16px;
  }
  .events-footer1 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .events-container13 {
    align-items: center;
    flex-direction: column;
  }
  .events-links-container {
    width: 50%;
    display: block;
    align-items: center;
    flex-direction: column;
  }
  .events-container14 {
    display: block;
    margin-right: 0px;
  }
  .events-container15 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .events-contact {
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .events-container17 {
    display: block;
  }
  .events-container18 {
    display: block;
  }
  .events-textinput1 {
    left: 250px;
  }
  .events-textinput2 {
    left: 10px;
  }
  .events-button2 {
    left: 80px;
    margin-top: var(--dl-space-space-twounits);
  }
  .events-container19 {
    margin-top: var(--dl-space-space-twounits);
  }
  .events-footer2 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
}
