.contact-form6-contact1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form6-section-title {
  gap: var(--dl-space-space-unit);
  width: 40%;
  height: 240px;
  align-self: center;
  align-items: flex-start;
}
.contact-form6-content {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 8px 0;
  padding-right: 8px 0;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: space-between;
}
.contact-form6-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form6-icon1 {
  fill: #f76a00;
  width: 30px;
}
.contact-form6-row2 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form6-icon3 {
  fill: #f76a00;
}
.contact-form6-phone {
  text-decoration: none;
}
.contact-form6-row3 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form6-icon5 {
  fill: #f76a00;
  width: 40px;
}
.contact-form6-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
}
.contact-form6-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-form6-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form6-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form6-text-input1 {
  font-family: "bold";
  border-color: none;
  border-radius: 0px;
}
.contact-form6-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form6-text-input2 {
  font-family: "bold";
  border-color: none;
  border-radius: 0px;
}
.contact-form6-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form6-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form6-textarea {
  width: 100%;
  height: 202px;
  align-self: flex-start;
  text-align: left;
  border-color: none;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
}
.contact-form6-button {
  width: 30%;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  border-radius: 0px;
  background-color: #f05b25;
}
.contact-form6root-class-name {
  width: 100%;
}
@media(max-width: 1600px) {
  .contact-form6root-class-name {
    height: var(--dl-size-size-maxwidth);
    margin-top: -60px;
  }
}
@media(max-width: 991px) {
  .contact-form6-section-title {
    width: 100%;
  }
  .contact-form6-form {
    width: 100%;
  }
  .contact-form6-container2 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form6-input1 {
    width: 50%;
  }
  .contact-form6-input2 {
    width: 50%;
  }
  .contact-form6-container3 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form6root-class-name {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .contact-form6-contact1 {
    height: 613px;
    margin-top: -150px;
  }
  .contact-form6-max-width {
    width: 423px;
  }
  .contact-form6-section-title {
    width: 35%;
    height: 393px;
    align-items: center;
  }
  .contact-form6-content {
    margin-left: 0px;
    padding-left: 0px;
  }
  .contact-form6-row1 {
    margin-left: 20px;
  }
  .contact-form6-icon1 {
    width: 50%;
  }
  .contact-form6-email {
    font-size: 12px;
  }
  .contact-form6-icon3 {
    width: 30%;
    font-size: 15px;
  }
  .contact-form6-phone {
    font-size: 12px;
  }
  .contact-form6-icon5 {
    width: 50%;
  }
  .contact-form6-address {
    font-size: 12px;
  }
  .contact-form6-form {
    width: 60%;
    padding: var(--dl-space-space-unit);
    align-self: center;
    margin-top: 12px;
  }
  .contact-form6-container2 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form6-input1 {
    width: 100%;
  }
  .contact-form6-input2 {
    width: 100%;
  }
  .contact-form6-textarea {
    height: 150px;
  }
  .contact-form6-button {
    width: 50%;
    margin-top: -10px;
  }
  .contact-form6root-class-name {
    width: 50%;
    height: 500px;
    margin-top: var(--dl-space-space-threeunits);
  }
}
