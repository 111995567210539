.contact-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.contact-image1 {
  width: 200px;
  height: auto;
}
.contact-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.contact-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.contact-links1 {
  flex: 1;
  width: 702px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: flex-end;
}
.contact-navlink1 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  transition: 0.3s;
  font-family: "extraa";
  font-weight: 700;
  text-decoration: none;
}
.contact-navlink1:hover {
  color: orange;
}
.contact-navlink2 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.contact-navlink3 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.contact-navlink4 {
  color: rgb(0, 130, 66);
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.contact-buttons1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-login1 {
  display: flex;
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
}
.contact-icon10 {
  fill: #008242;
  width: 24px;
  height: 24px;
  z-index: 100;
  border-color: var(--dl-color-success-500);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.contact-icon12 {
  fill: #008242;
  width: 24px;
  height: 24px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}
.contact-burger-menu {
  display: none;
}
.contact-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.contact-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.contact-logo {
  height: 2rem;
}
.contact-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text14 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-buttons2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-login2 {
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-gallery-card {
  width: 100%;
  height: 376px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: #fffafa;
  border-width: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/external/back%201-1500w.jpg");
}
.contact-image2 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.contact-container12 {
  width: 1160px;
  height: 555px;
  display: flex;
  align-items: center;
  animation-name: slideInUp;
  flex-direction: column;
  animation-delay: 0s;
  justify-content: flex-start;
  animation-duration: 600ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.contact-text15 {
  color: #008242;
  width: 318px;
  font-size: 40px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: "extraa";
}
.contact-text16 {
  font-size: 17px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "bold";
}
.contact-footer1 {
  width: 100%;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #008242;
}
.contact-container13 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact-links-container {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
}
.contact-container14 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.contact-product-container1 {
  width: 156px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-text22 {
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-product-container2 {
  width: 156px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-text31 {
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-company-container {
  width: 211px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-text41 {
  color: #fffcfc;
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-button1 {
  color: #ffffff;
  width: 204px;
  height: 36px;
  align-self: flex-start;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  border-width: 0px;
  background-color: #f05b25;
}
.contact-container15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-contact {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.contact-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-text50 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-icon-group2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-icon24 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.contact-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.contact-icon28 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-container16 {
  width: 100%;
  height: 222px;
  position: relative;
  align-items: center;
}
.contact-text51 {
  color: #008242;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-family: "extraa";
  font-weight: 800;
}
.contact-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.contact-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 10%;
  position: relative;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  justify-content: center;
}
.contact-textinput1 {
  top: -12px;
  left: 549px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  position: absolute;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.contact-textinput2 {
  top: -12px;
  left: 317px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  position: absolute;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.contact-button2 {
  left: 876px;
  color: rgb(255, 254, 254);
  width: var(--dl-size-size-large);
  bottom: -22px;
  margin: auto;
  position: absolute;
  font-size: 18px;
  align-self: center;
  font-family: "extraa";
  border-color: #008242;
  background-color: #008242;
}
.contact-container19 {
  width: 100%;
  height: 50%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  justify-content: center;
}
.contact-image3 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.contact-image4 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.contact-image5 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.contact-footer2 {
  width: 100%;
  height: 70px;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #008242;
}
.contact-text52 {
  top: 31px;
  left: 0px;
  color: #ffffff;
  right: 0px;
  margin: auto;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "bold";
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}
@media(max-width: 1600px) {
  .contact-text16 {
    line-height: 1.5;
  }
  .contact-footer1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .contact-product-container1 {
    height: auto;
    align-self: stretch;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 10rem;
  }

  .Heading {
    font-size: 18px !important;
}

  .contact-text22 {
    color: #ffffff;
    font-family: bold;
    font-weight: 700;
    line-height: 1.5;
  }
  .contact-product-container2 {
    height: auto;
    align-self: stretch;
    margin-left: 0px;
    margin-right: 10rem;
  }
  .contact-text31 {
    font-family: bold;
    font-weight: 700;
    margin-right: 0rem;
  }
  .contact-text32 {
    color: var(--dl-color-gray-white);
  }
  .contact-text33 {
    color: var(--dl-color-gray-white);
  }
  .contact-text34 {
    color: var(--dl-color-gray-white);
    font-size: 13px;
  }
  .contact-text35 {
    color: var(--dl-color-gray-white);
  }
  .contact-text36 {
    color: var(--dl-color-gray-white);
  }
  .contact-text37 {
    color: var(--dl-color-gray-white);
  }
  .contact-text38 {
    color: var(--dl-color-gray-white);
  }
  .contact-text39 {
    color: var(--dl-color-gray-white);
  }
  .contact-company-container {
    align-self: stretch;
  }
}
@media(max-width: 1200px) {
  .contact-container17 {
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .contact-container14 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-button2 {
    top: 6px;
    left: 700px;
    right: 92px;
  }
}
@media(max-width: 767px) {
  .contact-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-desktop-menu {
    display: none;
  }
  .contact-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-text10 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text11 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text12 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text13 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-text14 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-container13 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .contact-container14 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .contact-container18 {
    display: block;
  }
  .contact-textinput1 {
    left: 250px;
  }
  .contact-textinput2 {
    left: 10px;
  }
  .contact-button2 {
    left: 400px;
  }
  .contact-footer2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .contact-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .contact-mobile-menu {
    padding: 16px;
  }
  .contact-container12 {
    height: 647px;
  }
  .contact-text16 {
    width: 35%;
    height: var(--dl-size-size-medium);
    font-size: 13px;
  }
  .contact-footer1 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .contact-container13 {
    align-items: center;
    flex-direction: column;
  }
  .contact-links-container {
    width: 50%;
    display: block;
    align-items: center;
    flex-direction: column;
  }
  .contact-container14 {
    display: block;
    margin-right: 0px;
  }
  .contact-container15 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .contact-contact {
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-container17 {
    display: block;
  }
  .contact-container18 {
    display: block;
  }
  .contact-textinput1 {
    left: 250px;
  }
  .contact-textinput2 {
    left: 10px;
  }
  .contact-button2 {
    left: 80px;
    margin-top: var(--dl-space-space-twounits);
  }
  .contact-container19 {
    margin-top: var(--dl-space-space-twounits);
  }
  .contact-footer2 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
}
