.home-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image10 {
  width: 200px;
  height: auto;
}
.home-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: flex-end;
}
.home-navlink1 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  transition: 0.3s;
  font-family: "extraa";
  font-weight: 700;
  text-decoration: none;
}
.home-navlink1:hover {
  color: orange;
}
.home-navlink2 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink3 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink4 {
  color: #008242;
  font-size: 22px;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-buttons1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login1 {
  display: flex;
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon10 {
  fill: #008242;
  width: 24px;
  height: 24px;
  z-index: 100;
  border-color: var(--dl-color-success-500);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-icon12 {
  fill: #008242;
  width: 24px;
  height: 24px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-burger-menu {
  display: none;
}
.home-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  height: 2rem;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text14 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login2 {
  margin-right: var(--dl-space-space-twounits);
}
.home-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-gallery-card1 {
  width: 100%;
  height: 1305px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: fadeInLeft;
  flex-direction: column;
  animation-delay: 0s;
  background-size: cover;
  justify-content: center;
  background-image: url("/external/back%201-1400h.jpg");
  background-repeat: no-repeat;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
.home-container12 {
  width: 100%;
}
.home-image11 {
  width: 200px;
  display: inline;
  z-index: 200;
  align-self: stretch;
  margin-top: 150px;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-aboutus {
  color: #f05b25;
  font-size: 80px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  align-items: center;
  font-family: "extraa";
  font-weight: 700;
  margin-bottom: 0px;
  justify-content: center;
}
.home-text15 {
  color: #008242;
  width: 605px;
  height: 41px;
  font-size: 3em;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "extraa";
  font-weight: 800;
}
.home-hhhh1 {
  color: rgb(1, 2, 1);
  width: 80%;
  z-index: 1000;
  font-size: 30px;
  align-self: center;
  font-style: normal;
  margin-top: 120px;
  text-align: center;
  font-family: "bold";
  font-weight: 700;
  line-height: 1.5;
  margin-left: 0px;
  margin-bottom: 30px;
}
.home-separator {
  flex: 0 0 auto;
  width: 15%;
  height: 8px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 0px;
  border-color: #008242;
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  background-color: #008242;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-hhhh2 {
  color: rgb(1, 2, 1);
  width: 95%;
  z-index: 1000;
  font-size: 30px;
  align-self: center;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  font-style: normal;
  margin-top: 10px;
  text-align: center;
  font-family: "bold";
  font-weight: 700;
  line-height: 1.4;
  margin-left: 0px;
  margin-bottom: 80px;
}
.home-image12 {
  width: 300px;
  height: 144px;
  align-self: flex-end;
  object-fit: cover;
  margin-right: var(--dl-space-space-fourunits);
}
.home-icon24 {
  width: 24px;
  height: 24px;
}
.home-image13 {
  width: 100%;
  align-self: center;
  margin-top: 8%;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}
.home-gallery-card2 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: #fffafa;
  border-width: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/external/back%201-1400h.jpg");
}
.home-image14 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-container13 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: center;
  margin-top: 5%;
  align-items: center;
  justify-content: flex-start;
}
.home-image15 {
  width: 621px;
  height: 463px;
  margin-top: var(--dl-space-space-twounits);
  object-fit: contain;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}
.home-container14 {
  flex: 0 0 auto;
  width: 446px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container15 {
  flex: 0 0 auto;
  width: 446px;
  height: 288px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text16 {
  color: #008242;
  width: 316px;
  align-self: center;
  text-align: center;
  font-family: "extraa";
}
.home-text17 {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  margin-top: 150px;
  text-align: center;
  font-family: "bold";
  font-weight: 700;
}
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: space-around;
}
.home-button1 {
  width: 43%;
  height: 50%;
  display: flex;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
}
.home-icon26 {
  width: 24px;
  height: 24px;
}
.home-text18 {
  width: 100%;
  align-self: flex-start;
  padding-top: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
}
.home-text19 {
  font-size: 10px;
}
.home-text21 {
  font-style: normal;
  font-weight: 600;
}
.home-button2 {
  width: 43%;
  height: 51px;
  display: flex;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
}
.home-icon28 {
  width: 24px;
  height: 24px;
}
.home-text22 {
  align-self: flex-start;
  padding-top: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
}
.home-text23 {
  font-size: 10px;
}
.home-text24 {
  font-size: 10px;
}
.home-text26 {
  font-style: normal;
  font-weight: 600;
}
.home-container17 {
  width: 100%;
  height: 1681px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/external/back%202-1500w.jpg");
}
.home-image16 {
  width: 100%;
  object-fit: contain;
}
.home-subscribe-to-our-blog {
  color: rgb(0, 130, 66);
  height: 5%;
  font-size: 3em;
  align-self: center;
  font-style: normal;
  margin-top: 5%;
  font-family: "extraa";
  font-weight: 900;
}
.home-text30 {
  font-size: 30;
  align-self: center;
  font-style: normal;
  font-family: "bold";
  font-weight: 700;
}
.home-textinput1 {
  width: 40%;
  height: 5%;
  align-self: center;
  margin-top: 2%;
  border-color: #8c8a8a;
  border-width: 1px;
}
.home-button3 {
  width: 106%;
  height: 70%;
  font-size: 25px;
  align-self: center;
  margin-top: 14%;
  border-width: 0px;
  background-color: #f05b25;
}
.home-text31 {
  color: #fffefe;
  font-style: normal;
  font-family: "extraa";
  font-weight: 800;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-container18 {
  width: 818px;
  height: 298px;
  display: flex;
  align-self: center;
  margin-top: 50%;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  flex-direction: column;
  background-color: #fb9f41;
}
.home-text34 {
  color: #ffffff;
  font-size: 30px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: "extraa";
  font-weight: 700;
  margin-left: 0px;
  width: 70%;
}
.home-stats {
  width: 60%;
  height: 126px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: #fb9f41;
}
.home-stat1 {
  height: 111px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon30 {
  color: var(--dl-color-theme-primary2);
  width: 55px;
  height: 55px;
  border-radius: 25px;
  background-color: var(--dl-color-theme-secondary2);
}
.home-stat2 {
  height: 109px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon35 {
  color: var(--dl-color-theme-primary2);
  width: 55px;
  height: 55px;
  border-radius: 22px;
  background-color: var(--dl-color-gray-white);
}
.home-stat3 {
  height: 114px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon39 {
  color: var(--dl-color-theme-primary2);
  width: 55px;
  height: 55px;
  border-radius: 22px;
  background-color: var(--dl-color-theme-secondary1);
}
.home-stat4 {
  height: 114px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon46 {
  color: var(--dl-color-theme-primary2);
  width: 55px;
  height: 55px;
  border-radius: 22px;
  background-color: var(--dl-color-theme-secondary1);
}
.home-stat5 {
  height: 114px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-icon62 {
  color: var(--dl-color-theme-primary2);
  width: 55px;
  height: 55px;
  border-radius: 22px;
  background-color: var(--dl-color-theme-secondary1);
}
.home-button4 {
  color: #ffffff;
  width: 195px;
  height: 42px;
  font-size: 20px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "bold";
  font-weight: 700;
  border-width: 0px;
  background-color: #008242;
}
.home-footer1 {
  width: 100%;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #008242;
}
.home-container19 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-links-container {
  width: 100%;
  height: 391px;
  align-self: center;
  justify-content: center;
}
.home-container20 {
  width: 90%;
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.home-product-container1 {
  width: 422px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text35 {
  font-size: 20px;
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-product-container2 {
  width: 777px;
  height: 160px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text44 {
  font-size: 20px;
  font-family: "bold";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-company-container {
  width: 533px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text54 {
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "bold";
  font-weight: 700;
  width: 87%;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-button5 {
  color: #ffffff;
  width: 50%;
  height: 30%;
  align-self: center;
  font-style: normal;
  font-family: "extraa";
  font-weight: 700;
  border-width: 0px;
  background-color: #f05b25;
}
.home-container21 {
  width: 322px;
  height: 74px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-contact {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text63 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
}
.home-icon-group2 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-icon66 {
  width: 30%;
  height: 20%;
  margin-right: var(--dl-space-space-unit);
}
.home-icon68 {
  width: 30%;
  height: 20%;
  margin-right: var(--dl-space-space-unit);
}
.home-icon70 {
  width: 30%;
  height: 20%;
}
.home-container22 {
  width: 100%;
  height: 222px;
  position: relative;
  align-items: center;
}
.home-text64 {
  color: #008242;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-family: "extraa";
  font-weight: 800;
}
.home-container23 {
  width: 30%;
  height: 50%;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: 40%;
  justify-content: center;
}
.home-textinput2 {
  top: -12px;
  left: 549px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  border-width: 0px;
  background-color: rgba(117, 114, 114, 0.18);
}
.home-textinput3 {
  top: -12px;
  left: 317px;
  width: var(--dl-size-size-xlarge);
  margin: auto;
  margin-left: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  background-color: rgba(117, 114, 114, 0.18);
}
.home-button6 {
  left: 876px;
  color: rgb(255, 254, 254);
  width: var(--dl-size-size-large);
  bottom: -22px;
  margin: auto;
  font-size: 18px;
  align-self: center;
  font-family: "extraa";
  border-color: #008242;
  background-color: #008242;
}
.home-container24 {
  width: 100%;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-image17 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.home-image18 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.home-image19 {
  width: 113px;
  height: 45px;
  align-self: center;
  object-fit: contain;
}
.home-footer2 {
  width: 100%;
  height: 70px;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #008242;
}
.home-text65 {
  top: 31px;
  left: 0px;
  color: #ffffff;
  right: 0px;
  margin: auto;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "bold";
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}
@media(max-width: 1600px) {
  .home-image15 {
    margin-left: -20%;
  }
  .home-container18 {
    height: 317px;
  }
  .home-icon30 {
    width: 55px;
    height: 55px;
    border-radius: 25px;
  }
  .home-icon35 {
    width: 55px;
    height: 55px;
  }
  .home-icon39 {
    width: 55px;
    height: 55px;
  }
  .home-icon46 {
    width: 55px;
    height: 55px;
  }
  .home-icon62 {
    width: 55px;
    height: 55px;
  }
  .home-product-container1 {
    height: auto;
    align-self: stretch;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 10rem;
  }
  .home-text35 {
    color: #ffffff;
    font-family: bold;
    font-weight: 700;
    line-height: 1.5;
  }
  .home-product-container2 {
    height: auto;
    align-self: stretch;
    margin-left: 0px;
    margin-right: 10rem;
  }
  .home-text44 {
    font-family: bold;
    font-weight: 700;
    margin-right: 0rem;
  }
  .home-text45 {
    color: var(--dl-color-gray-white);
  }
  .home-text46 {
    color: var(--dl-color-gray-white);
  }
  .home-text47 {
    color: var(--dl-color-gray-white);
  }
  .home-text48 {
    color: var(--dl-color-gray-white);
  }
  .home-text49 {
    color: var(--dl-color-gray-white);
  }
  .home-text50 {
    color: var(--dl-color-gray-white);
  }
  .home-text51 {
    color: var(--dl-color-gray-white);
  }
  .home-text52 {
    color: var(--dl-color-gray-white);
  }
  .home-company-container {
    align-self: stretch;
  }
}
@media(max-width: 1200px) {
  .home-image15 {
    width: 472px;
    height: 642px;
    margin-left: -150px;
  }
  .home-container15 {
    animation-name: fadeInDown;
    animation-delay: 0s;
    animation-duration: 500ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .home-container18 {
    height: 352px;
  }
  .home-icon30 {
    width: 44px;
    height: 44px;
  }
  .home-icon35 {
    width: 44px;
    height: 44px;
  }
  .home-icon39 {
    width: 44px;
    height: 44px;
  }
  .home-icon46 {
    width: 44px;
    height: 44px;
  }
  .home-icon62 {
    width: 44px;
    height: 44px;
  }
  .home-product-container1 {
    width: 351px;
  }
  .home-icon66 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-small);
  }
  .home-icon68 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-small);
  }
  .home-icon70 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-small);
  }
  .home-button6 {
    bottom: 0px;
  }
  .home-container24 {
    margin-top: -10px;
  }
}
@media(max-width: 991px) {
  .home-image15 {
    width: 400px;
  }
  .home-text17 {
    margin-top: 20px;
  }
  .home-container16 {
    width: 100%;
    height: 100px;
    display: block;
  }
  .home-container20 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-button6 {
    top: 6px;
    left: 700px;
    right: 92px;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text10 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text11 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text12 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text13 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text14 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-container12 {
    display: flex;
    margin-top: 200px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-image11 {
    width: 150px;
  }
  .home-aboutus {
    margin-top: 200px;
    margin-left: 100px;
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image12 {
    width: 200px;
    margin-top: -100px;
  }
  .home-image13 {
    top: -100px;
    margin-bottom: 200px;
  }
  .home-image15 {
    width: 300px;
    height: 400px;
  }
  .home-container15 {
    margin-top: var(--dl-space-space-fourunits);
  }
  .home-text16 {
    margin-left: -200px;
  }
  .home-text17 {
    width: 300px;
  }
  .home-container16 {
    display: block;
    margin-top: 8px;
  }
  .home-button3 {
    width: 243px;
    height: 57px;
  }
  .home-stats {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-stat1 {
    padding: var(--dl-space-space-unit);
  }
  .home-stat2 {
    padding: var(--dl-space-space-unit);
  }
  .home-stat3 {
    padding: var(--dl-space-space-unit);
  }
  .home-stat4 {
    padding: var(--dl-space-space-unit);
  }
  .home-stat5 {
    padding: var(--dl-space-space-unit);
  }
  .home-container19 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .home-container20 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-product-container1 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-product-container2 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-button5 {
    align-self: flex-start;
  }
  .home-textinput2 {
    left: 250px;
  }
  .home-textinput3 {
    left: 0px;
  }
  .home-button6 {
    left: 400px;
  }
  .home-footer2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-product-container1 {
    margin-left: -25px;
  }
  
  .home-text31{
    font-size: 18px;
    width: 70%;
  }
  .home-image11 {
    width: 20%;
    height: 40%;
  }
  .home-aboutus {
    font-size: 50px;
    align-self: center;
    margin-left: 10px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text15 {
    color: rgb(0, 130, 66);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-family: extraa;
    font-weight: 800;
    margin-left: 0px;
    width: 70%;
  }
  .home-hhhh1 {
    color: rgb(1, 2, 1);
    width: 80%;
    font-size: 22px;
    align-self: center;
    font-style: normal;
    margin-top: 60px;
    font-family: bold;
    font-weight: 700;
    line-height: 1.5;
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-hhhh2 {
    color: rgb(1, 2, 1);
    font-size: 22px;
    box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
    font-style: normal;
    font-family: bold;
    font-weight: 700;
    line-height: 1.4;
  }
  .home-image12 {
    width: 200px;
    margin-top: 10px;
  }
  .home-image15 {
    width: 205px;
    height: 250px;
    margin-left: -103px;
}
  .home-text16 {
    font-size: 25px;
    margin-top: 70px;
    margin-left: -250px;
  }
  .home-text17 {
    width: 200px;
    font-size: 16px;
    margin-top: 10px;
  }
  .home-container16 {
    display: block;
    margin-top: 8px;
  }
  .home-button2 {
    margin-top: 10px;
  }
  .home-container17 {
    height: 1000px;
  }
  .home-subscribe-to-our-blog {
    font-size: 30px;
    align-self: center;
    margin-left: 2% !important;
  }
  .home-text30 {
    font-size: 25;
  }
  .home-button3 {
    width: 100%;
    height: 81%;
    margin-top: 20px;
  }
  .home-container18 {
    width: 436px;
    bottom: 20%;
    height: 301px;
    margin-top: 300px;
  }
  .home-stats {
    width: 90%;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-stat1 {
    height: 70px;
  }
  .home-container19 {
    align-items: center;
    flex-direction: column;
  }
  .home-links-container {
    width: 50%;
    display: block;
    align-items: center;
    flex-direction: column;
  }
  .home-container20 {
    width: 100%;
    margin-left: -50%;
  }
  .home-company-container {
    width: 230px;
  }
  .home-button5 {
    width: 70%;
    align-self: flex-start;
  }
  .home-container21 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .home-contact {
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text64 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-container23 {
    width: 20%;
    display: grid;
    margin-left: 200px;
  }
  .home-textinput2 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-textinput3 {
    margin-top: 10px;
  }
  .home-button6 {
    left: 80px;
    margin-top: var(--dl-space-space-unit);
  }
  .home-container24 {
    margin-top: var(--dl-space-space-fourunits);
  }
  .home-footer2 {
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
}
